var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('main',[_c('BreadCrumb',{attrs:{"breadCrumbData":_vm.breadCrumb,"margin":"none"}}),_vm._v(" "),_c('EsySection',{attrs:{"variant":"price-listing"}},[_c('GridContainer',[_c('EsyH1',[_c('strong',[_vm._v("Aktuelle Heizölpreise für\n          "+_vm._s(_vm.translateStateName(_vm.$route.params.category))+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"flex-container"},[_c('DataTile',{staticClass:"state-data-tile",attrs:{"headline":`${_vm.translateStateName(_vm.$route.params.category)}`,"data":_vm.stateAssetsPrice,"description":`Die Heizöl-Durchschnittspreise für ${_vm.translateStateName(
            _vm.$route.params.category,
          )}`,"disclaimer":_vm.Descriptions.statesPriceDisclaimerWithState.replace(
              '##state##',

              _vm.translateStateName(_vm.$route.params.category),
            )}}),_vm._v(" "),_c('div',{staticClass:"main-content-container"},[_c('EsySelect',{attrs:{"label":"Zeitraum","variant":"filled","options":_vm.timeRangeOptions,"selected":_vm.monthsQuantity,"disabled":false},on:{"emit-selected-value":function($event){return _vm.changeMonthsQuantity($event)}}}),_vm._v(" "),_c('EsySection',{attrs:{"id":"state-chart-section","variant":"inside-grid"}},[_c('ComparsionChart',{staticClass:"state-line-chart",attrs:{"yAchsesLabel":_vm.$store.state.federalstates.statesOilPriceHistory.reletadUnits,"chartDataLabels":_vm.$store.state.federalstates.statesOilPriceHistory.labels,"mainChartDataLabel":_vm.translateStateName(_vm.$route.params.category),"mainChartDataSeries":_vm.$store.state.federalstates.statesOilPriceHistory
                  .sateOilPriceData,"comparsionChartDataSeries":_vm.$store.state.federalstates.statesOilPriceHistory
                  .nationalOiPriceData,"comparsionChartDataLabel":"Bundesrepublik","chartDescription":_vm.Descriptions.nationalPriceDisclaimer,"canvasID":"state-line-chart"}})],1),_vm._v(" "),(
              _vm.translateStateName(_vm.$route.params.category) !=
              ('Hamburg' || 'Bremen' || 'Berlin')
            )?_c('EsySection',{attrs:{"variant":"inside-grid"}},[_c('EsyH2',[_c('strong',[_vm._v("Heizölpreise der größten Städte")]),_vm._v(" in\n              "+_vm._s(_vm.translateStateName(_vm.$route.params.category))+"\n            ")]),_vm._v(" "),_c('OilImportTable',{attrs:{"table-header-data":_vm.getTableHeaderData(
                  _vm.$store.state.federalstates.topStateCities,
                  'oil',
                ),"table-row-data":_vm.formatTopCitiesTableData(
                  _vm.$store.state.federalstates.topStateCities,
                  'oil',
                ),"hideMobileColumns":true}})],1):_vm._e(),_vm._v(" "),(
              _vm.translateStateName(_vm.$route.params.category) !=
              ('Hamburg' || 'Bremen' || 'Berlin')
            )?_c('DistrictLocationsList',{attrs:{"locations":_vm.$store.state.federalstates.countyLinks,"districtName":_vm.translateStateName(_vm.$route.params.category),"isPelletsSite":false,"isStatePage":true}}):_vm._e(),_vm._v(" "),_c('DistrictOtherLocations',{attrs:{"stateDistrictCities":_vm.districtCities,"otherCountiesInBundesland":[],"routeParams":_vm.$route.params,"moreThanTwentyCounties":false,"isStatePage":true}}),_vm._v(" "),_c('EsySection',{attrs:{"variant":"inside-grid"}},[_c('PriceNotification',{attrs:{"title":`esyoil Preiswecker für ${_vm.translateStateName(
                _vm.$route.params.category,
              )}`}})],1),_vm._v(" "),(_vm.historicalHighestDataState)?_c('PriceComparisonHistoricalData',{attrs:{"historicalHighestData":_vm.historicalHighestDataState,"historicalLowestData":_vm.historicalLowestDataState,"title":_vm.formattedTitle,"hasCustomStyle":true,"isHistoricalData":true}}):_vm._e(),_vm._v(" "),(
              _vm.translateStateName(_vm.$route.params.category) !=
              ('Hamburg' || 'Bremen' || 'Berlin')
            )?_c('EsySection',{attrs:{"variant":"inside-grid"}},[_c('EsyH2',[_c('strong',[_vm._v("\n                Heizöl im Bundesland\n                "+_vm._s(_vm.translateStateName(_vm.$route.params.category))+"\n              ")])]),_vm._v(" "),_c('EsyArticle',[_c('EsyParagraph',[_vm._v("\n                Der durchschnittliche 100L-Heizölpreis bei 3000L-Bestellmenge\n                beträgt in\n                "+_vm._s(_vm.translateStateName(_vm.$route.params.category))+" derzeit\n                "),_c('strong',[_vm._v(_vm._s(_vm.currentAverageStatePrice))]),_vm._v("\n                und liegt "+_vm._s(_vm.priceComparisonText)+" Durchschnitt der letzten 3\n                Monate sowie "+_vm._s(_vm.priceComparisonText7Days)+"\n                Durchschnitt der letzten 7 Tage. Der höchste 100L-Heizölpreis\n                in den letzten 3 Monaten betrug\n                "+_vm._s(this.stateYearStatistics.highest.threeMonths.price)+" €,\n                der niedrigste Preis für 100L Heizöl betrug\n                "+_vm._s(this.stateYearStatistics.lowest.threeMonths.price)+" €.\n              ")]),_vm._v(" "),_c('EsyParagraph',[_vm._v("\n                Der Liter Heizöl kostet heute in\n                "+_vm._s(_vm.translateStateName(_vm.$route.params.category))+" im\n                Durchschnitt\n                "),_c('strong',[_vm._v(_vm._s(_vm.currentAverageStatePrice)+" Cent.")]),_vm._v("\n                Das sind "),_c('strong',[_vm._v(_vm._s(_vm.lastPriceDifferenceText))])]),_vm._v(" "),_vm._l((_vm.$store.state.federalstates
                  .stateArticle.intro),function(paragraph,i){return _c('EsyParagraph',{key:`${i}-para`},[_vm._v("\n                "+_vm._s(paragraph)+"\n              ")])}),_vm._v(" "),_c('EsyImg',{attrs:{"src":_vm.$store.state.federalstates.stateArticle.imageUrl,"alt":`Heizölpreise ${_vm.translateStateName(
                  _vm.$route.params.category,
                )} aktuell - Heute Heizöl kaufen`,"width":"724","height":"415"}}),_vm._v(" "),_vm._l((_vm.$store.state.federalstates.stateArticle
                  .content),function(content,i){return _c('div',{key:`${i}-article`},[_c('EsyH3',[_vm._v(" "+_vm._s(content.headline)+" ")]),_vm._v(" "),_vm._l((content.text),function(paragraph,i){return _c('EsyParagraph',{key:`${i}-article-para`},[_vm._v("\n                  "+_vm._s(paragraph)+"\n                ")])})],2)})],2)],1):_vm._e(),_vm._v(" "),(
              _vm.translateStateName(_vm.$route.params.category) !=
              ('Hamburg' || 'Bremen' || 'Berlin')
            )?_c('EsySection',{attrs:{"variant":"inside-grid"}},[_c('EsyH2',[_c('strong',[_vm._v("Unsere Top Heizölhändler")]),_vm._v(" aus\n              "+_vm._s(_vm.translateStateName(_vm.$route.params.category))+"\n            ")]),_vm._v(" "),_c('ul',{staticClass:"user-comments-list"},_vm._l((_vm.$store.state.federalstates.topStatePartners),function(partner){return _c('ListItem',{key:partner.dea_id},[_c('PartnerTile',{attrs:{"name":partner.dea_co_short,"slug":partner.dea_company_seo,"profileSlug":partner.profile_slug,"rating":partner.pre_grade,"ratingQuantity":partner.pre_num_customer_rating_total,"imageURL":partner.logoUrl}})],1)}),1)],1):_vm._e(),_vm._v(" "),(
              _vm.translateStateName(_vm.$route.params.category) !=
              ('Hamburg' || 'Bremen' || 'Berlin')
            )?_c('EsySection',{attrs:{"variant":"inside-grid"}},[_c('EsyH2',[_c('strong',[_vm._v("Bewertungen von Heizölkäufern")]),_vm._v(" aus\n              "+_vm._s(_vm.translateStateName(_vm.$route.params.category))+"\n            ")]),_vm._v(" "),_c('ul',{staticClass:"user-comments-list"},_vm._l((_vm.$store.state.federalstates
                  .topStateUserComments),function(comment,i){return _c('ListItem',{key:`${i}-comments`},[_c('UserComment',{attrs:{"userRatingData":comment}})],1)}),1)],1):_vm._e(),_vm._v(" "),_c('EsySection',{attrs:{"variant":"inside-grid"}},[_c('EsyH2',[_c('strong',[_vm._v("Heizölpreis-Tendenz in Städten")]),_vm._v(" in\n              "+_vm._s(_vm.translateStateName(_vm.$route.params.category))+"\n            ")]),_vm._v(" "),_c('EsyParagraph',[_vm._v("\n              Für nähere Informationen zum Heizölpreis in weiteren Städten im\n              Bundesland\n              "+_vm._s(_vm.translateStateName(_vm.$route.params.category))+" wählen Sie eine\n              Stadt aus der Liste.\n            ")]),_vm._v(" "),_c('ul',{staticClass:"all-cities-list"},_vm._l((_vm.$store.state.federalstates.bigCityLinks),function(cities,i){return _c('ListItem',{directives:[{name:"show",rawName:"v-show",value:(cities.length > 0),expression:"cities.length > 0"}],key:`${i}-cities`},[(cities.length !== 0)?_c('EsyParagraph',{staticClass:"index-label"},[_vm._v("\n                  "+_vm._s(cities[0].name.charAt(0).toUpperCase())+"\n                ")]):_vm._e(),_vm._v(" "),_c('span',_vm._l((cities),function(city,i){return _c('RouterLink',{key:`${i}-city`,attrs:{"href":decodeURIComponent(city.link),"title":`Weiter zur Ansicht zu ${city.name}`}},[_vm._v("\n                    "+_vm._s(city.name)+"\n                  ")])}),1)],1)}),1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }